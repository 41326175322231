/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "../../SCSS/ContactPage.scss"
import Button from './Button';
import '../../SCSS/popups.scss';
import { IoCopy } from "react-icons/io5";
import { FiExternalLink } from 'react-icons/fi';
import { useFormik } from 'formik';
import { useCompanyIndustry, useCompanyType, useCompanyOwners } from '../../hooks/company';
import { Tooltip, Spin, Drawer } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getSingleHSCompany, updateHSCompany } from '../../../API/authCurd';
import { amountCheck, cleanAnnualRevenue, constructHubSpotFileUrl, deletePropertiesIfExists, extractIds, formatCurrency, getNameInitials, sendEmail, urlRedirect } from '../../../Utils/helpers';
import EditableAvatar from './EditableAvatar';
import EditCompanyTooltip from '../../commonModules/UI/EditCompanyTooltip';
import { hsObjectSource } from '../../../Utils/staticdata';
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import SearchDropdown from './SearchDropdown';
import ContactAssociation from './associationForms/contact/ContactAssociation';
import CompanyAssociation from './associationForms/company/CompanyAssociation';
import DealAssociation from './associationForms/deals/DealAssociation';
import { ErrorMessage } from '../../hooks/hubspot';

const initialValues = {
  domain: '',
  hubspot_owner_id: '',
  industry: '',
  type: '',
  city: '',
  state: '',
  zip: '',
  numberofemployees: '',
  annualrevenue: '',
  timezone: '',
  description: '',
  linkedin_company_page: '',
  hs_object_source_label: '',
}

function PreviewButtonCompany({ record, onPreviewClick }) {
  return (
    <>
      <button className='preview-btn' onClick={() => { onPreviewClick(record) }}>
        Preview
      </button>
    </>
  );
}

const CompanyOffCanvas = ({ show, setShow, record, afterDelete, onClose, viewRecord, openFileUpload, openFileRemove, sendMessage, removeContactAssociation, onContactPreviewClick, onCompanyPreviewClick, onDealPreviewClick }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [copyText, setCopyText] = useState("Copy domain to clipboard")
  const [copyTextPhone, setCopyTextPhone] = useState("Copy phone to clipboard")
  const { companyOwners, getCompanyOwners } = useCompanyOwners();
  const { companyType, getCompanyType } = useCompanyType();
  const { companyIndustry, getCompanyIndustry } = useCompanyIndustry();
  const [companyData, setCompanyData] = useState(null)
  const [contactLoading, setCompanyLoading] = useState(false);

  const [contactAssociations, setContactAssociations] = useState(null)
  const [companyAssociations, setCompanyAssociations] = useState(null)
  const [dealAssociations, setDealAssociations] = useState(null)
  const [showLoader, setShowLoader] = useState({ contacts: false, companies: false, deals: false })

  const { errors, resetForm, getFieldProps, handleSubmit, touched, values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: (values) => {
      let data = { ...values }
      if (data.hsObjectSource) {
        delete data.hsObjectSource; // Alternatively, you can use `unset`
      }
      deletePropertiesIfExists(data, ["hs_object_source_label"]);
      if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
      data.annualrevenue = cleanAnnualRevenue(data.annualrevenue || "")
      setIsSaving(true)
      updateHSCompany(record?.id, data).then((res) => {
        if(res.data?.original?.code == '403'){
          let message = res.data?.original?.error || ""
          sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
        } else {
        getSingleCompanyData(record?.id)
        // closeButtonHandler()
        sendMessage({ content: 'Changes saved' })
        }
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  async function getSingleCompanyData(id, loader = false) {
    if (loader) {
      setCompanyLoading(true)
      setShowLoader({ contacts: true, companies: true, deals: true })
    }
    return getSingleHSCompany(id).then((res) => {
      if (res?.data?.companies?.[0]) {
        setCompanyData(res?.data?.companies?.[0])
        setCompanyAssociations(res?.data?.companies?.[0]?.associations?.associatedCompanies ? res?.data?.companies?.[0]?.associations?.associatedCompanies : null)
        setContactAssociations(res?.data?.companies?.[0]?.associations?.contacts ? res?.data?.companies?.[0]?.associations?.contacts : null)
        setDealAssociations(res?.data?.companies?.[0]?.associations?.deals ? res?.data?.companies?.[0]?.associations?.deals : null)
      }
      return res
    }).catch(() => { setCompanyData(null); return })
      .finally(() => {
        setShowLoader({ contacts: false, companies: false, deals: false })
        setCompanyLoading(false)
      })
  }

  useEffect(() => {
    if (companyData) {
      Object.keys(initialValues).forEach((field) => {
        let value = companyData?.properties?.[field] ? companyData?.properties?.[field] : "";

        if (field === 'annualrevenue' && value) {
          value = formatCurrency(value);
        }

        setFieldValue(field, value);
      })
    }
  }, [companyData, setFieldValue])

  function showButtonHandler() {
    getCompanyOwners()
    getCompanyType()
    getCompanyIndustry()
    setShow(true)
    if (record) { getSingleCompanyData(record?.id, true) }
  }

  useEffect(() => {
    getCompanyOwners()
    getCompanyType()
    getCompanyIndustry()
    if (record) { getSingleCompanyData(record?.id, true) }
  }, [record])

  function closeButtonHandler() {
    setShow(false)
    setIsSaving(false)
    resetForm()
    if (onClose) { onClose(record) }
  }

  const getName = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : '--'
  }

  const getNameOrDomain = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
  }

  const DomainHandleCopy = (record) => {
    if (record?.properties?.domain) {
      navigator.clipboard.writeText(record?.properties?.domain).then(() => {
        setCopyText('Copied');
        setTimeout(() => {
          setCopyText('Copy domain to clipboard');
        }, 2000)
      }).catch(err => { });
    }
  };
  const PhoneHandleCopy = (record) => {
    if (record?.properties?.phone) {
      navigator.clipboard.writeText(record?.properties?.phone).then(() => {
        setCopyTextPhone('Copied');
        setTimeout(() => {
          setCopyTextPhone('Copy phone to clipboard');
        }, 2000)
      }).catch(err => { });
    }
  };

  // delete button handler
  const deleteCompanyHandler = async () => {
    if (afterDelete) {
      afterDelete(record).then((data) => {
        closeButtonHandler()
      }).catch((err) => { })
    }
  }

  const handleUpload = () => {
    openFileUpload(record)
  };

  const handleRemove = () => {
    openFileRemove(record)
  };
  const onUpdateHandler = (load) => {
    if (load) {
      return getSingleCompanyData(record?.id)
    }
  }

  const getContactName = (data) => {
    let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
    return name ? name : data?.properties?.email
  }

  const contactAssociationRemove = async (e, data) => {
    let msg = `${getContactName(data)} will no longer be associated with ${getNameOrDomain(companyData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }
  const companyAssociationRemove = async (e, data) => {
    let msg = `${getNameOrDomain(data)} will no longer be associated with ${getNameOrDomain(companyData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }
  const getDealName = (data) => {
    let name = `${data?.properties?.dealname ? data?.properties?.dealname : ""}`.trim()
    return name ? name : '--'
  }
  const dealAssociationRemove = async (e, data) => {
    let msg = `${getDealName(data)} will no longer be associated with ${getNameOrDomain(companyData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }

  const getNameForDeal = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : ''
  }

  return (
    <Drawer placement='right' title={
      <div className='drawer-title'>
        {getNameOrDomain(companyData)}
      </div>
    }
      destroyOnClose={true}
      open={show}
      onClose={closeButtonHandler}
    >
      <div className='drawer-content-wrapper'>
        {contactLoading ? <div className='d-flex justify-content-center align-items-center p-1 my-5'>
          <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
        </div> : <>
          <div className='personal-info-wrap'>
            <EditableAvatar
              initials={getNameInitials(companyData?.properties?.name ? companyData?.properties?.name : companyData?.properties?.domain)}
              onUpload={handleUpload}
              onRemove={handleRemove}
              imgUrl={companyData?.properties?.hs_avatar_filemanager_key ? constructHubSpotFileUrl(companyData?.properties?.hs_avatar_filemanager_key) : companyData?.properties?.hs_logo_url}
            />
            <div className='personal-info'>
              <h6>
                {getName(companyData)}
              </h6>
              {companyData?.properties?.domain && <p>
                <a
                  href={`https://${companyData?.properties?.domain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {companyData?.properties?.domain}
                </a>
                <Tooltip placement="top" title={'domain'}>
                  <FiExternalLink className={`ms-2`} onClick={() => urlRedirect(companyData?.properties?.domain)} />
                </Tooltip>
                <Tooltip placement="top" title={copyText}>
                  <IoCopy className={`ms-2`} onClick={() => DomainHandleCopy(companyData)} />
                </Tooltip>

              </p>}
              <p>
                {companyData?.properties?.phone}
                {companyData?.properties?.phone && (
                  <Tooltip placement="top" title={copyTextPhone}>
                    <IoCopy className="ms-2" onClick={() => PhoneHandleCopy(companyData)} />
                  </Tooltip>
                )}
              </p>
            </div>
            <EditCompanyTooltip record={companyData} id={companyData?.id} onUpdate={onUpdateHandler} sendMessage={sendMessage} />
          </div>
          <div className='main-btn-wrap'>
          </div></>}
        <div className='form-wrap'>
          <h5>About this company</h5>
          <div>
            <FloatingLabel label="Company Domain Name">
              <Form.Control
                type="text"
                name='domain'
                {...getFieldProps("domain")}
              />
            </FloatingLabel>
            {(touched.domain && errors.domain) ? <span className='ms-2 text-danger'>{errors.domain}</span> : null}
          </div>
          <SearchDropdown
            label="Industry"
            options={[
              { label: "Select an industry", value: "" },
              ...sortObjectsByAttribute(companyIndustry, 'displayOrder')
            ]}
            // placeholder="Choose..."
            placeholder="Select an industry"
            onSelect={(value) => setFieldValue('industry', value)}
            initialValue={values?.industry ?? "default"}
          />
          <SearchDropdown
            label="Company Owner"
            options={[
              { label: "No Owner", value: 'default' },
              ...sortByConcatenatedString(companyOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email }) => ({
                value: id,
                label: `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${email ? "(" + email + ")" : ""}`.trim()
              }))]}
            // placeholder="Choose..."
            placeholder="Select an owner"
            onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
            initialValue={values?.hubspot_owner_id ?? "default"}
          />
          <SearchDropdown
            label="Type"
            options={[
              { label: "Select a type", value: "" },
              ...sortObjectsByAttribute(companyType, 'displayOrder')
            ]}
            // placeholder="Choose..."
            placeholder="Select a type"
            onSelect={(value) => setFieldValue('type', value)}
            initialValue={values?.type ?? "default"}
          />
          <div>
            <FloatingLabel label='City' className='custom-select'>
              <Form.Control
                type="text"
                value={companyData?.properties?.city}
                name='city'
                  placeholder="city"
                {...getFieldProps("city")}
                disabled={false}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel label='State/Region' className='custom-select'>
              <Form.Control
                type="text"
                value={companyData?.properties?.state}
                name='state'
                 placeholder="state"
                {...getFieldProps("state")}
                disabled={false}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel label='Postal Code' className='custom-select'>
              <Form.Control
                type="text"
                value
                name='zip'
                placeholder="zip"
                {...getFieldProps("zip")}
                disabled={false}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel label='Number Of Employees' className='custom-select'>
              <Form.Control
                type="text"
                value
                name='numberofemployees'
                  placeholder="'number of employees"
                {...getFieldProps("numberofemployees")}
                disabled={false}
                onKeyDown={(e) => amountCheck(e)}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel label='Annual Revenue' className='custom-select'>
              <Form.Control
                type="text"
                value={values?.annualrevenue}
                name='annualrevenue'
                 placeholder="annual revenue"
                {...getFieldProps("annualrevenue")}
                onKeyDown={(e) => amountCheck(e)}
                disabled={false}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel label='Time Zone' className='custom-select'>
              <Form.Control
                type="text"
                value
                name='timezone'
                 placeholder="time zone "
                {...getFieldProps("timezone")}
                disabled={false}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel label='Description' className='custom-select'>
              <Form.Control
                type="text"
                value
                name='description'
                 placeholder="description"
                {...getFieldProps("description")}
                disabled={false}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel label='LinkedIn Company Page' className='custom-select'>
              <Form.Control
                type="text"
                value
                name='linkedin_company_page'
                placeholder="linkedin company page"
                {...getFieldProps("linkedin_company_page")}
                disabled={false}
              />
            </FloatingLabel>
          </div>

          <div>
            <FloatingLabel label='Record Source' className='custom-select'>
              <Form.Select
                aria-label="Record Source"
                name='hs_object_source_label'
                {...getFieldProps("hs_object_source_label")}
                disabled={true}
              >
                <option value="" key="0">--</option>
                {hsObjectSource?.map((item) => {
                  return <option value={item.id} key={item.id}>{item.name}</option>
                })}
              </Form.Select>
            </FloatingLabel>
          </div>
          <div className='border-bottom'></div>
          <ContactAssociation
            id={companyData?.id}
            section={'companies'}
            type={'company_to_contact'}
            associate_to={'contacts'}
            associatedContacts={contactAssociations?.results ? contactAssociations?.results : []}
            associatedContactIds={extractIds(contactAssociations?.results ? contactAssociations?.results : [])}
            fetchData={getSingleCompanyData}
            sendMessage={sendMessage}
            handleRemove={contactAssociationRemove}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            onContactPreviewClick={onContactPreviewClick}
          />
          <div className='border-bottom'></div>
          <CompanyAssociation
            id={companyData?.id}
            section={'companies'}
            type={'company_to_company'}
            associate_to={'companies'}
            associatedCompanies={companyAssociations ? companyAssociations : []}
            associatedCompanyIds={extractIds(companyAssociations ? companyAssociations : [])}
            fetchData={getSingleCompanyData}
            sendMessage={sendMessage}
            handleRemove={companyAssociationRemove}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            onCompanyPreviewClick={onCompanyPreviewClick}
          />
          <div className='border-bottom'></div>
          <DealAssociation
            id={companyData?.id}
            section={'companies'}
            type={'company_to_deal'}
            associate_to={'deals'}
            associatedDeals={dealAssociations?.results ? dealAssociations?.results : []}
            associatedDealIds={extractIds(dealAssociations?.results ? dealAssociations?.results : [])}
            fetchData={getSingleCompanyData}
            sendMessage={sendMessage}
            handleRemove={dealAssociationRemove}
            name={`${getNameForDeal(companyData)}`.trim()}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            onDealPreviewClick={onDealPreviewClick}
          />

          <div className='d-flex align-items-center justify-content-center'>
            <div>
              <Button className="mx-4 cclBtn" onClick={closeButtonHandler}>Cancel</Button>
              <Button disabled={isSaving} type="button" onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
            </div>
            <a className="mx-4 view-record-link" onClick={(e) => { viewRecord(e, companyData) }}>View record</a>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export { PreviewButtonCompany, CompanyOffCanvas };