/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "../../SCSS/ContactPage.scss"
import Button from './Button';
import '../../SCSS/popups.scss';
import { useFormik } from 'formik';
import { Spin, DatePicker, Drawer } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getSingleHSDeal, updateHSDeal } from '../../../API/authCurd';
import { convertDateToTimestamp, parseDateTimeString } from '../../../Utils/dateFormat';
import { hsObjectSource } from '../../../Utils/staticdata';
import EditDealTooltip from './EditDealTooltip';
import dayjs from "dayjs"
import 'dayjs/locale/en';
import { useContactOwners } from '../../hooks/contacts';
import { useDealsPipelines, useDealsProps } from '../../hooks/deals';
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import AppointmentDropdown from './AppointmentDropdown';
import '../../SCSS/deals.scss'
import SearchDropdown from './SearchDropdown';
import { extractIds, formatCurrency } from '../../../Utils/helpers';
import { DealCloseDateEditMessage, ErrorMessage } from '../../hooks/hubspot';
import ContactAssociation from './associationForms/contact/ContactAssociation';
import CompanyAssociation from './associationForms/company/CompanyAssociation';
import DealAssociation from './associationForms/deals/DealAssociation';

const initialValues = {
  hubspot_owner_id: '',
  dealtype: '',
  hs_priority: '',
}

function PreviewButtonDeal({ record, onPreviewClick }) {
  return (
    <>
      <button className='preview-btn' onClick={(e) => { onPreviewClick(record) }}>
        Preview
      </button>
    </>
  );
}

function DealsOffCanvas({ show, setShow, record, onClose, viewRecord, sendMessage, removeContactAssociation, handleSetPrimary, removePrimaryAssociation, onContactPreviewClick, onCompanyPreviewClick, onDealPreviewClick }) {
  const [isSaving, setIsSaving] = useState(false)
  const [dealData, setDealsData] = useState(null)
  const [dealLoading, setDealLoading] = useState(false);
  const [closeDate, setCloseDate] = useState(null)
  const [dealStage, setDealStage] = useState(null)
  const { contactOwners, getContactOwners } = useContactOwners();
  const { getDealsProps, dealTypes, dealPriority } = useDealsProps();
  const { dealsPipelines, getDealsPipelines } = useDealsPipelines();
  const [stageOptions, setStageOptions] = useState([])

  const [contactAssociations, setContactAssociations] = useState([])
  const [companyAssociations, setCompanyAssociations] = useState([])
  const [dealAssociations, setDealAssociations] = useState([])
  const [dealsPrimaryCompany, setDealsPrimaryCompany] = useState(null)
  const [showLoader, setShowLoader] = useState({ contacts: false, companies: false, deals: false })

  const { errors, resetForm, getFieldProps, handleSubmit, touched, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: (values) => {
      let data = { ...values }
      if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
      setIsSaving(true)
      updateHSDeal(record?.id, data).then((res) => {
        if(res.data?.original?.code == '403'){
          let message = res.data?.original?.error || ""
          sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
        } else {
        sendMessage({ content: 'Changes saved' })
        getSingleDealData(record?.id)
        }
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  function getSingleDealData(id, loader = false) {
    if (loader) {
      setDealLoading(true)
      setShowLoader({ contacts: true, companies: true, deals: true })
    }
    getSingleHSDeal(id).then((res) => {
      if (res?.data?.original?.Deals) {
        setCompanyAssociations(res?.data?.original?.associations?.companies ? res?.data?.original?.associations?.companies : [])
        setContactAssociations(res?.data?.original?.associations?.contacts ? res?.data?.original?.associations?.contacts : [])
        setDealAssociations(res?.data?.original?.associations?.deals ? res?.data?.original?.associations?.deals : [])
        setDealsData(res?.data?.original?.Deals)
        setDealsPrimaryCompany(res?.data?.original?.associations?.primaryCompany?.results?.[0] ? res?.data?.original?.associations?.primaryCompany?.results?.[0] : null)
      }
    }).catch(() => { setDealsData(null) })
      .finally(() => {
        setShowLoader({ contacts: false, companies: false, deals: false })
        setDealLoading(false)
      })
  }

  useEffect(() => {
    if (dealData) {
      Object.keys(initialValues).forEach((field) => {
        setFieldValue(field, dealData?.properties?.[field] ? dealData?.properties?.[field] : "")
      })
      setCloseDate(dealData?.properties?.closedate ? parseDateTimeString(dealData?.properties?.closedate, 6) : null)
      setDealStage(dealData?.properties?.dealstage)
      let stages = dealsPipelines?.find((item) => item.id == dealData?.properties?.pipeline)
      setStageOptions(stages?.stages ? stages?.stages : [])
    }
  }, [dealData, setFieldValue, dealsPipelines])

  const onDateChangeHandler = (date, dateString) => {
    setCloseDate(date)
    let data = { closedate: convertDateToTimestamp(dateString) }
    updateHSDeal(record?.id, data).then((res) => {
      if(res.data?.original?.code == '403'){
        let message = res.data?.original?.error || ""
        sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
      } else {
      sendMessage({ content: <DealCloseDateEditMessage date={res?.data?.properties?.closedate} /> })
      getSingleDealData(record?.id)
      }
    })
  }

  const onDealStageChange = (value) => {
    setDealStage(value)
    let data = { dealstage: value }
    updateHSDeal(record?.id, data).then((res) => {
      if(res.data?.original?.code == '403'){
        let message = res.data?.original?.error || ""
        sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
      } else {
      sendMessage({ content: 'Success. Your changes were updated.' })
      getSingleDealData(record?.id)
      }
    })
  }

  useEffect(() => {
    setShow(true)
    getContactOwners()
    getDealsProps()
    getDealsPipelines()
    if (record) { getSingleDealData(record?.id, true) }
  }, [record])

  function closeButtonHandler() {
    setShow(false)
    setIsSaving(false)
    resetForm()
    if (onClose) { onClose(record) }
  }

  const getName = (data) => {
    let name = `${data?.properties?.dealname ? data?.properties?.dealname : ""}`.trim()
    return name ? name : '--'
  }

  const onUpdateHandler = (load) => {
    if (load) {
      getSingleDealData(record?.id)
    }
  }

  const getContactName = (data) => {
    let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
    return name ? name : data?.properties?.email
  }

  const contactAssociationRemove = async (e, data) => {
    let msg = `${getContactName(data)} will no longer be associated with ${getName(dealData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }

  const dealAssociationRemove = async (e, data) => {
    let msg = `${getName(data)} will no longer be associated with ${getName(dealData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }

  const getNameOrDomain = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
  }

  const companyAssociationRemove = async (e, data) => {
    let msg = `${getNameOrDomain(data)} will no longer be associated with ${getName(dealData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }

  const handleRemovePrimary = async (e, data, primaryKey, companies) => {
    let options = companies?.map((item) => {
      let label = item?.properties?.name ? item?.properties?.name : item?.properties?.domain
      return {
        label: label ? label : '--',
        value: item?.id
      }
    })
    let filteredOpt = options?.filter((item) => { return item?.value != primaryKey })
    let heading = `Remove ${getNameOrDomain(data)} as primary`
    let subHeading = `${getNameOrDomain(data)} was the primary company for ${getName(dealData)}. Optionally choose a new primary company for this deal before removing this one.`
    return await removePrimaryAssociation(`Primary company for this deal`, heading, primaryKey, filteredOpt ? filteredOpt : [], subHeading)
  }

  const getNameForDeal = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : ''
  }

  const handleRemovePrimaryAssociation = async (e, data, primaryKey, companies) => {
    let options = companies?.map((item) => {
      let label = item?.properties?.name ? item?.properties?.name : item?.properties?.domain
      return {
        label: label ? label : '--',
        value: item?.id
      }
    })
    let filteredOpt = options?.filter((item) => { return item?.value != primaryKey })
    let heading = `Remove ${getNameOrDomain(data)} as primary`
    let warning = `${getNameOrDomain(data)} will no longer be associated with ${getName(dealData)}`
    let subHeading = `${getNameOrDomain(data)} was the primary company for ${getName(dealData)}. Optionally choose a new primary company for this deal before removing this one.`
    return await removePrimaryAssociation(`Primary company for this deal`, heading, primaryKey, filteredOpt ? filteredOpt : [], subHeading, warning)
  }

  return (
    <>
      <Drawer placement='right' title={
        <div className='drawer-title'>
          {getName(dealData)}
        </div>
      }
        destroyOnClose={true}
        open={show}
        onClose={closeButtonHandler}
      >
        <div className='drawer-content-wrapper'>
          {dealLoading ? <div className='d-flex justify-content-center align-items-center p-1 my-5'>
            <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
          </div> : <>
            <div className='personal-info-wrap'>
              <div className='deals-details-wrap'>
                <div className='personal-info'>
                  <h6>
                    {getName(dealData)}
                  </h6>
                  <p>Amount: {`${dealData?.properties?.amount ? formatCurrency(dealData?.properties?.amount, false) : '--'}`}</p>
                  <div className='closedate'>
                    <label style={{ display: "block" }}>Close Date</label>
                    <DatePicker
                      name='closedate'
                      format="MM/DD/YYYY"
                      className='myDatePicker'
                      onChange={onDateChangeHandler}
                      value={closeDate ? dayjs(closeDate, "MM/DD/YYYY") : ""}
                    />
                  </div>
                  <div className='appointment-date'>
                    <label style={{ display: "block" }}>Stage: </label>
                    <AppointmentDropdown
                      options={[...sortObjectsByAttribute(stageOptions, 'displayOrder').map(({ id, label }) => ({ value: id, label: label }))]}
                      onSelect={onDealStageChange}
                      fieldValue={dealStage}
                      placeholder='Select a stage'
                    />
                  </div>
                </div>
                <EditDealTooltip record={dealData} id={record?.id} onUpdate={onUpdateHandler} sendMessage={sendMessage} />
              </div>
            </div>
            <div className='main-btn-wrap'>
            </div></>}
          <div className='form-wrap'>
            <h5>About this deal</h5>
            <div>
              <SearchDropdown
                label="Deal Owner"
                options={[
                  { label: "No Owner", value: 'default' },
                  ...sortByConcatenatedString(contactOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email }) => ({
                    value: id,
                    label: `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${email ? "(" + email + ")" : ""}`.trim()
                  }))]}
                // placeholder="Choose..."
                placeholder="Select an owner"
                onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
                initialValue={values?.hubspot_owner_id}
              />
              {(touched.hubspot_owner_id && errors.hubspot_owner_id) ? <span className='ms-2 text-danger'>{errors.hubspot_owner_id}</span> : null}
            </div>
            <div>
              <FloatingLabel label='Last Contacted' className='custom-select'>
                <Form.Control
                  type="text"
                  value={dealData?.properties?.notes_last_contacted ? parseDateTimeString(dealData?.properties?.notes_last_contacted, 16) : '--'}
                  disabled={true}
                />
              </FloatingLabel>
            </div>
            <div>
              <SearchDropdown
                label="Deal Type"
                options={[
                  { label: "Select a type", value: "" },
                  ...sortObjectsByAttribute(dealTypes, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a type"
                onSelect={(value) => setFieldValue('dealtype', value)}
                initialValue={values?.dealtype}
              />
              {(touched.dealtype && errors.dealtype) ? <span className='ms-2 text-danger'>{errors.dealtype}</span> : null}
            </div>
            <div>
              <SearchDropdown
                label="Priority"
                options={[
                  { label: "Select a priority", value: "" },
                  ...sortObjectsByAttribute(dealPriority, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a priority"
                onSelect={(value) => setFieldValue('hs_priority', value)}
                initialValue={values?.hs_priority}
              />
              {(touched.hs_priority && errors.hs_priority) ? <span className='ms-2 text-danger'>{errors.hs_priority}</span> : null}
            </div>
            <div>
              <FloatingLabel label='Record Source' className='custom-select'>
                <Form.Select
                  aria-label="Record Source"
                  name='hs_object_source'
                  value={dealData?.properties?.hs_object_source ? dealData?.properties?.hs_object_source : ""}
                  disabled={true}
                >
                  <option value="" key="0">--</option>
                  {hsObjectSource?.map((item) => {
                    return <option value={item.id} key={item.id}>{item.name}</option>
                  })}
                </Form.Select>
              </FloatingLabel>
            </div>

            <div className='border-bottom'></div>
            <ContactAssociation
              id={dealData?.id}
              section={'deals'}
              type={'deal_to_contact'}
              associate_to={'contacts'}
              associatedContacts={contactAssociations ? contactAssociations : []}
              associatedContactIds={extractIds(contactAssociations ? contactAssociations : [])}
              fetchData={getSingleDealData}
              sendMessage={sendMessage}
              handleRemove={contactAssociationRemove}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              onContactPreviewClick={onContactPreviewClick}
            />
            <div className='border-bottom'></div>
            <CompanyAssociation
              id={dealData?.id}
              section={'deals'}
              type={'deal_to_company'}
              associate_to={'companies'}
              associatedCompanies={companyAssociations ? companyAssociations : []}
              associatedCompanyIds={extractIds(companyAssociations ? companyAssociations : [])}
              fetchData={getSingleDealData}
              sendMessage={sendMessage}
              handleRemove={companyAssociationRemove}
              handleSetPrimary={(e, data, pk, type, section, associate_to) => { return handleSetPrimary(e, data, pk, type, section, associate_to, dealData, dealsPrimaryCompany) }}
              primaryKey={dealsPrimaryCompany?.id}
              handleRemovePrimary={handleRemovePrimary}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              primaryCompanyName={`${getNameForDeal(dealsPrimaryCompany)}`.trim()}
              name={getName(dealData)}
              handleRemovePrimaryAssociation={handleRemovePrimaryAssociation}
              onCompanyPreviewClick={onCompanyPreviewClick}
            />
            <div className='border-bottom'></div>
            <DealAssociation
              id={dealData?.id}
              section={'deals'}
              type={'deal_to_deal'}
              associate_to={'deals'}
              associatedDeals={dealAssociations ? dealAssociations : []}
              associatedDealIds={extractIds(dealAssociations ? dealAssociations : [])}
              fetchData={getSingleDealData}
              sendMessage={sendMessage}
              handleRemove={dealAssociationRemove}
              primaryKey={dealsPrimaryCompany?.id}
              name={`${getNameForDeal(dealsPrimaryCompany)}`.trim()}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              onDealPreviewClick={onDealPreviewClick}
            />

            <div className='d-flex align-items-center justify-content-center'>
              <div>
                <Button className="mx-4 cclBtn" onClick={closeButtonHandler}>Cancel</Button>
                <Button disabled={isSaving} type="button" onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
              </div>
              <a className="mx-4 view-record-link" onClick={(e) => { viewRecord(e, dealData) }}>View record</a>
            </div>
          </div>
        </div >
      </Drawer>
    </>
  );
}

export { PreviewButtonDeal, DealsOffCanvas };