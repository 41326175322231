/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../../../Utils/sortFunctions'
import SearchDropdown from '../../SearchDropdown'
import { FloatingLabel, Form } from 'react-bootstrap'
import Button from '../../Button'
import MyTooltip from '../../MyTooltip'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import PhoneNumberPopover from '../../PhoneNumberPopover'
import { useContactLeadStatus, useContactLifeCycle, useContactOwners } from '../../../../hooks/contacts'
import { GlobalSearch } from '../../../../contexts/GlobalSearchContext'
import { useFormik } from 'formik'
import { hsContactSchema } from '../../../../../Utils/validation'
import { addHSContact, editAssociation } from '../../../../../API/authCurd'
import { EditMessage, ErrorMessage } from '../../../../hooks/hubspot'

const initialValues = {
    email: '',
    firstname: '',
    lastname: '',
    hubspot_owner_id: '',
    jobtitle: '',
    phone: '',
    lifecyclestage: 'lead',
    hs_lead_status: '',
    hs_marketable_status: false,
}

const CreateContactForm = ({ sendMessage, onClose, contactId, type, associate_to, section }) => {
    const [isSaving, setIsSaving] = useState(false)
    const { contactOwners, getContactOwners } = useContactOwners();
    const { contactLifecycles, getContactLifecycles } = useContactLifeCycle();
    const { contactLeadStatus, getContactLeadStatus } = useContactLeadStatus();
    const [areFieldsEnabled, setAreFieldsEnabled] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    const { userData } = useContext(GlobalSearch)

    const { errors, resetForm, getFieldProps, handleSubmit, touched, values, setFieldValue, setFieldError } = useFormik({
        initialValues,
        validationSchema: hsContactSchema,
        onSubmit: (values) => {
            setIsSaving(true)
            let data = { ...values }
            if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
            addHSContact(data).then((res) => {
                if (res.data?.original?.code == '403') {
                    let message = res.data?.original?.error || ""
                    sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> })
                    setIsSaving(false)
                } else {
                let response = res.data
                if (response?.status == "error" && response?.errors) {
                    Object.keys(response?.errors).forEach((item) => {
                        setFieldError(item, response?.errors?.[item])
                    })
                } else {
                    if (response?.id) {
                        let payload = {}
                        payload.from = contactId
                        payload.to = [response?.id]
                        payload.type = type
                        payload.section = section
                        payload.associateTo = associate_to
                        editAssociation(payload).then((res) => {
                            sendMessage({ content: <EditMessage msg={'Your contact was created.'} /> })
                            closeButtonHandler()
                        }).finally(() => { setIsSaving(false) })
                    } else {
                        sendMessage({ content: <EditMessage msg={'Your contact was created.'} /> })
                        closeButtonHandler()
                    }
                }
            }
            }).catch((err) => { })
        },
    });

    function showButtonHandler() {
        getContactOwners()
        getContactLifecycles()
        getContactLeadStatus()
    }

    useEffect(() => {
        showButtonHandler()
    }, [])

    function closeButtonHandler() {
        setIsSaving(false)
        onClose()
        resetForm()
    }

    useEffect(() => {
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email);
        const isPrimaryFieldValid = (values.firstname || values.lastname || (values.email && isEmailValid));
        setAreFieldsEnabled(!!isPrimaryFieldValid);
    }, [values.firstname, values.lastname, values.email]);

    const onPhoneApplyClick = (val, isFormatted) => {
        setFieldValue('phone', val)
        setFieldValue('is_phone_formatted', isFormatted)
    }

    useEffect(() => {
        if (contactOwners) {
            let currentUser = contactOwners?.find((item) => item.email === userData.email);
            if (currentUser) { setFieldValue('hubspot_owner_id', currentUser?.id) }
        }

    }, [contactOwners, userData])

    useEffect(() => {
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email);

        const isFirstOrLastNameValid = values.firstname.trim() !== '' || values.lastname.trim() !== '';
        const isEmailValidOrEmpty = values.email.trim() === '' || isEmailValid;

        setIsSaveEnabled((isFirstOrLastNameValid || isEmailValid) && isEmailValidOrEmpty);
    }, [values.firstname, values.lastname, values.email]);

    return (
        <div className='form-wrap'>
            <div>
                <FloatingLabel label="Email">
                    <Form.Control
                        type="text"
                        placeholder=''
                        name='email'
                        {...getFieldProps("email")}
                    />
                </FloatingLabel>
                {(touched.email && errors.email) ? <span className='ms-2 text-danger'>{errors.email}</span> : null}
            </div>
            <div>
                <FloatingLabel label="First Name">
                    <Form.Control
                        type="text"
                        name='firstname'
                        {...getFieldProps("firstname")}
                        placeholder="First Name"
                    />
                </FloatingLabel>
                {(touched.firstname && errors.firstname) ? <span className='ms-2 text-danger'>{errors.firstname}</span> : null}
            </div>
            <div>
                <FloatingLabel label="Last Name">
                    <Form.Control
                        type="text"
                        placeholder=''
                        name='lastname'
                        {...getFieldProps("lastname")}
                    />
                </FloatingLabel>
                {(touched.lastname && errors.lastname) ? <span className='ms-2 text-danger'>{errors.lastname}</span> : null}
            </div>
            <SearchDropdown
                label="Contact Owner"
                options={[
                    { label: "No Owner", value: 'default' },
                    ...sortByConcatenatedString(contactOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email }) => ({
                        value: id,
                        label: `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${email ? "(" + email + ")" : ""}`.trim()
                    }))]}
                // placeholder="Choose..."
                placeholder="Select an owner"
                onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
                initialValue={values?.hubspot_owner_id}
                disabled={!areFieldsEnabled}
            />
            <div>
                <FloatingLabel label="Job Title">
                    <Form.Control
                        type="text"
                        name='jobtitle'
                        placeholder=''
                        {...getFieldProps("jobtitle")}
                        disabled={!areFieldsEnabled}
                    />
                </FloatingLabel>
                {(touched.jobtitle && errors.jobtitle) ? <span className='ms-2 text-danger'>{errors.jobtitle}</span> : null}
            </div>
            <div>
                <PhoneNumberPopover
                    type="text"
                    name='phone'
                    onApplyClick={onPhoneApplyClick}
                    value={values.phone ?? ""}
                    placeholder={'Phone Number'}
                    placement={'top'}
                    disabled={!areFieldsEnabled}
                    isFormatted={values?.is_phone_formatted ? true : false}
                />
            </div>
            <SearchDropdown
                label="Lifecycle Stage"
                options={[
                    { label: "Select a stage", value: "" },
                    ...sortObjectsByAttribute(contactLifecycles, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a stage"
                onSelect={(value) => setFieldValue('lifecyclestage', value)}
                initialValue={values?.lifecyclestage}
                disabled={!areFieldsEnabled}
            />
            <SearchDropdown
                label="Lead Status"
                options={[
                    { label: "Select a status", value: "" },
                    ...sortObjectsByAttribute(contactLeadStatus, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a status"
                onSelect={(value) => setFieldValue('hs_lead_status', value)}
                initialValue={values?.hs_lead_status}
                disabled={!areFieldsEnabled}
            />
            <div className='allow'>
                <p className='mb-0'>Allow your team to market to this contact</p>
                <div className='check-box-wrap'>
                    <input
                        type="checkbox"
                        name='hs_marketable_status'
                        {...getFieldProps("hs_marketable_status")}
                        id='hs_marketable_status'
                    />
                    <label className='check-box-label' htmlFor='hs_marketable_status'> Set this contact as a marketing contact</label></div>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
                <Button className="mx-4 cclBtn" onClick={closeButtonHandler}>Cancel</Button>
                {values?.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email) ? <MyTooltip title={'Please update invalid property values.'}>
                    <Button type="button" disabled={isSaving || !areFieldsEnabled || !isSaveEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                </MyTooltip> :
                    <Button type="button" disabled={isSaving || !areFieldsEnabled || !isSaveEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>}
            </div>
        </div>
    )
}

export default CreateContactForm