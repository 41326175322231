import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import { IoMdInformationCircle } from "react-icons/io";
import '../../SCSS/SearchDropdown.scss';
import { IoChevronDownOutline } from "react-icons/io5";
function SearchDropdown({ label, options = [], placeholder = "Select an option", onSelect, initialValue, disabled, tooltip }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const searchRef = useRef(null)

  const dropdownRef = useRef(null);

  useEffect(() => {
    // Set the initial value on component mount or when initialValue changes
    if (initialValue) {
      const selectedOption = options.find(option => option.value === initialValue);
      if (selectedOption) {
        setSelectedValue(selectedOption.label);
      }
    }
  }, [initialValue, options]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (item) => {
    setSelectedValue(item.label);  // Set the selected label as the value
    setIsOpen(false);
    if (onSelect) {
      onSelect(item.value);  // Pass the value to the onSelect callback
    }
  };

  const filteredItems = options?.filter((item) =>
    item?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }} className={`selectdropdown ${disabled ? 'disabled-field' : ''}`}>
      <label onClick={toggleDropdown} htmlFor={searchRef.current} className={`select-label ${disabled ? 'disabled-label' : ''}`}>{label} {tooltip && <Tooltip title={tooltip}>
        <IoMdInformationCircle />
      </Tooltip>}</label>
      <input
        className='searchable-dropdown'
        ref={searchRef}
        type="text"
        value={selectedValue}
        onClick={toggleDropdown}
        readOnly
        placeholder={placeholder}
        style={{ cursor: 'pointer' }}
        disabled={disabled || false}
      />
  <IoChevronDownOutline className='down-arrow'/>
      {isOpen && (
        <div className="dropdown">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className='search-box'
          />
          <ul>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleSelect(item)}
                  style={{
                    backgroundColor: selectedValue === item.label ? '#d3d3d3' : 'transparent',
                    cursor: 'pointer',
                    padding: '5px'
                  }}
                  className='search-option'
                >
                  {item.label}
                </li>
              ))
            ) : (
              <li>No items found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SearchDropdown;
