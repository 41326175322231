/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { fetchDealPipelines, fetchDealProps, fetchDeals, fetchDealStages } from "../../API/authCurd";

const useDealsProps = ({ initialLoad = false } = {}) => {
    const [dealsProps, setDealsProps] = useState([]);
    const [dealTypes, setDealTypes] = useState(null);
    const [dealReferralSources, setDealReferralSources] = useState(null);
    const [dealPriority, setDealPriority] = useState(null);
    const [loadingDealsProps, setLoadingDealsProps] = useState(false);

    async function getDealsProps(loader = false) {
        if (loader) { setLoadingDealsProps(true) }
        return fetchDealProps().then((res) => {
            let data = res?.data?.deals ? res?.data?.deals : []
            let dealtypes = data.find((item) => item.name == 'dealtype')
            let dealpriority = data.find((item) => item.name == 'hs_priority')
            let dealRefSrc = data.find((item) => item.name == 'referral_sources')
            setDealsProps(data)
            setDealTypes(dealtypes?.options ? dealtypes?.options : [])
            setDealPriority(dealpriority?.options ? dealpriority?.options : [])
            setDealReferralSources(dealRefSrc?.options ? dealRefSrc?.options : [])
            setLoadingDealsProps(false)
            return Promise.resolve(data)
        }).catch((err) => {
            setDealsProps([])
            setLoadingDealsProps(false)
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getDealsProps();
        }
    }, [initialLoad]);

    return { dealsProps, setDealsProps, getDealsProps, loadingDealsProps, setLoadingDealsProps, dealTypes, setDealTypes, dealPriority, setDealPriority, dealReferralSources, setDealReferralSources };
}

const useDealsPipelines = ({ initialLoad = false } = {}) => {
    const [dealsPipelines, setDealsPipelines] = useState([]);
    const [loadingDealsPipelines, setLoadingDealsPipelines] = useState(false);

    async function getDealsPipelines(loader = false) {
        if (loader) { setLoadingDealsPipelines(true) }
        return fetchDealPipelines().then((res) => {
            let data = res?.data?.pipelines ? res?.data?.pipelines : []
            setDealsPipelines(data)
            setLoadingDealsPipelines(false)
            return Promise.resolve(data)
        }).catch((err) => {
            setDealsPipelines([])
            setLoadingDealsPipelines(false)
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getDealsPipelines();
        }
    }, [initialLoad]);

    return { dealsPipelines, setDealsPipelines, getDealsPipelines, loadingDealsPipelines, setLoadingDealsPipelines };
}

const useDealList = ({ initialLoad = false } = {}) => {
    const [deals, setDeals] = useState([]);

    async function getDeals() {
        return fetchDeals().then((res) => {
            let data = res?.data ? res?.data : []
            let newData = data?.map(({ id, dealname }) => {
                let name = `${dealname || ''}`.trim()
                return {
                    value: id,
                    name: name ? name : '--',
                    label: name ? name : '--'
                }
            })
            setDeals(newData)
            return Promise.resolve(data)
        }).catch((err) => {
            setDeals([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getDeals()
        }
    }, [initialLoad]);

    return { deals, setDeals, getDeals }
}

const useDealStages = ({ initialLoad = false } = {}) => {
    const [dealStageList, setDealStageList] = useState([]);

    async function getDealStageList() {
        return fetchDealStages().then((res) => {
            let data = res?.data?.deal_stages ? res?.data?.deal_stages : []
            let newData = data?.map(({ stage_id, pipeline_name, stage_label }) => {
                let name = `${stage_label ? stage_label : ""} ${pipeline_name ? "(" + pipeline_name + ")" : ""}`.trim()
                return {
                    id: stage_id,
                    value: stage_id,
                    name: name ? name : '--',
                    label: name ? name : '--'
                }
            })
            setDealStageList(newData)
            return Promise.resolve(data)
        }).catch((err) => {
            setDealStageList([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getDealStageList()
        }
    }, [initialLoad]);

    return { dealStageList, setDealStageList, getDealStageList }
}

export { useDealsProps, useDealsPipelines, useDealList, useDealStages }